import React, { Component } from 'react';

class Skills extends Component {
  
  render() {

    if(this.props.data){
      var skillmessage = this.props.data.skillmessage;

      var skills = this.props.data.skills.map((skills)=>{
        return (
          <p>{skills.name}</p>
        )
      })
    }

    return (
      <section id="skills">

      <div className="row skill">

         <div className="three columns header-col">
            <h1><span>Skills</span></h1>
         </div>

         <div className="nine columns main-col">
              <p><br/></p>
              <p>{skillmessage}<br/></p>
              <div className="skillsnormal">{skills}</div>
			    </div>
      </div>
   </section>
    );
  }
}

export default Skills;
