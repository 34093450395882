import React, { Component } from 'react';

class Project extends Component {
  
  render() {

    if(this.props.data){
      var projectmessage = this.props.data.projectmessage;
      var projects = this.props.data.projects.map(function(projects){
        return <div key={projects.name}><h3>{projects.name}</h3>
            <p className="info">{projects.role}</p>
            <p>{projects.description}</p>
        </div>
      })
    }

    return (
      <section id="project">

        <div className="row project">

          <div className="three columns header-col">
            <h1><span>Projects</span></h1>
          </div>

          <div className="nine columns main-col">
              <p><br/></p>
              <p>{projectmessage}<br/></p>
              <div>{projects}</div>
          </div>
        </div>
      </section>
    );
  }
}

export default Project;
