import React, { Component } from 'react';

class About extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var profilepic= "images/"+this.props.data.image;
      var certificate1="images/"+this.props.data.certificate1;
      var certificate1url = this.props.data.certificate1url;
      var certificate2="images/"+this.props.data.certificate2;
      var certificate2url = this.props.data.certificate2url;
      var bio = this.props.data.bio;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var country = this.props.data.address.country;
      var zip = this.props.data.address.zip;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
      var resumeDownload = this.props.data.resumedownload;
    }

    return (
      <section id="about">
         <div className="row">
            <div className="three columns"/>
            <div className="nine columns main-col">
               <img className="profile-pic" src={profilepic} alt="Jijo Lawrence Profile Pic" />
            </div>
         </div>
      <div className="row">
         <div className="three columns">
            <a href={certificate1url} target="_blank">
               <img className="certificate-pic" src={certificate1} alt="AWS Certificatied Solution Architect - Associate"/>
            </a>  
            <a href={certificate2url} target="_blank">
               <img className="certificate-pic" src={certificate2} alt="PCF JAVA Certificate"/>
            </a>  
         </div>
         <div className="nine columns main-col">
            <h2>About Me</h2>
            <p>{bio}</p>
            <h2>Contact Details</h2>
            <p className="address">
               <span>{name}</span><br /> 
               <span>{city}, {state}, {country}</span><br />
               <span>Email: {email}</span>
            </p>
         </div>
      </div>

   </section>
    );
  }
}

export default About;
