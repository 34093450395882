import React, { Component } from 'react';

class Blog extends Component {
  render() {

    if (this.props.data) {
      var blogs = this.props.data.blogs.map(function (blogs) {
        var projectImage = 'images/blog/' + blogs.image;
        
        return (
          <div key={blogs.url} className="columns blog-item">
            <div className="item-wrap">
              <a href={blogs.url} title={blogs.message} target="_blank">
                <img alt={blogs.url} src={projectImage} />
                <div className="overlay">
                  <div className="blog-item-meta">
                    <h5>{blogs.message}</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
        )
      })
    }

    return (
      <section id="blog">

        <div className="row">

          <div className="twelve columns collapsed">

            <h1>My blog. Click on the image below.</h1>

            <div id="blog-wrapper">
              {blogs}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Blog;
